import React, { useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { useTabState, Tab, TabList } from '@mc/design-system'
import { TrackingContainer } from '@mc/visibility'
import { useTranslation } from '@mc/i18n'

import { RowAnalytics } from '../utils'
import { usePersonaEvents } from '../../../events'
import { useDebouncedCallback } from './useDebounceCallback'

import classes from './GoalsSection.module.scss'

type GoalTabsProps = {
  rowAnalytics: RowAnalytics
}

const DEBOUNCE = 100

const TAB_IDS = { CAREER: 'career', PERSONAL: 'personal' }
const TABS = [TAB_IDS.CAREER, TAB_IDS.PERSONAL]

export const GoalTabs = ({ rowAnalytics }: GoalTabsProps) => {
  const { t } = useTranslation('@mc/persona')
  const tabState = useTabState({
    selectedId: TAB_IDS.CAREER,
  })

  const [prevSelectedId, setPrevSelectedId] = useState(TAB_IDS.CAREER)

  const {
    trackPersonaSignClick,
    trackPersonaRowTileViewed,
    trackPersonaRowScrolled,
  } = usePersonaEvents()

  const tabPanelContainerRef = useRef<HTMLDivElement>(null)
  const tabPanelRefs = TABS.map(() => React.createRef<HTMLUListElement>())

  // EFFECTS
  // Scroll to middle
  useEffect(() => {
    const scrollToMiddle = () => {
      if (!tabPanelContainerRef.current) return

      const contentWidth =
        tabPanelContainerRef.current.scrollWidth -
        tabPanelContainerRef.current.clientWidth

      tabPanelContainerRef.current.scrollTo({
        left: contentWidth / TABS.length,
        behavior: 'smooth',
      })
    }

    return () => window.removeEventListener('resize', scrollToMiddle)
  }, [tabState.selectedId, rowAnalytics])

  // Trigger rowScrolled event as a side effect of tab change to account for scroll and tab click
  useEffect(() => {
    if (tabState.selectedId === prevSelectedId) return

    const currentActiveIndex = TABS.findIndex(
      (id) => id === tabState.selectedId,
    )
    const prevActiveIndex = TABS.findIndex((id) => id === prevSelectedId)

    trackPersonaRowScrolled({
      ...rowAnalytics,
      autoScroll: false,
      direction: prevActiveIndex < currentActiveIndex ? 'right' : 'left',
    })

    setPrevSelectedId(tabState.selectedId ?? TAB_IDS.CAREER)
  }, [
    prevSelectedId,
    tabState.selectedId,
    rowAnalytics,
    trackPersonaRowScrolled,
  ])

  // EVENT HANDLERS
  const handleTabVisible = (index: number) => {
    trackPersonaRowTileViewed({
      ...rowAnalytics,
      tilePositionIndex: index,
      tileTitle: `Goals list for ${TABS[index]}`,
    })
  }

  const handleTabClick = useCallback(
    (newActiveIndex: number) => {
      const tabPanelRef = tabPanelRefs[newActiveIndex]

      trackPersonaSignClick({
        location: rowAnalytics.rowName,
        cta: TABS[newActiveIndex],
        action: 'select tab',
      })

      if (!tabPanelRef.current || !tabPanelContainerRef.current) return

      tabPanelContainerRef.current.scrollTo({
        left:
          tabPanelRef.current.offsetLeft -
          tabPanelContainerRef.current.clientWidth / 2,
        behavior: 'smooth',
      })
    },
    [tabPanelRefs, trackPersonaSignClick, rowAnalytics.rowName],
  )

  const handleScroll = useDebouncedCallback(() => {
    if (!tabPanelContainerRef.current) return

    const newActiveIndex = tabPanelRefs.findIndex((tabPanelRef) => {
      if (!tabPanelRef.current || !tabPanelContainerRef.current) return false
      const rect = tabPanelRef.current.getBoundingClientRect()
      const { left, right } = rect

      const center = tabPanelContainerRef.current.clientWidth / 2
      return left <= center && right >= center
    })

    if (newActiveIndex !== -1) {
      tabState.setSelectedId(TABS[newActiveIndex])
    }
  }, DEBOUNCE)

  return (
    <div className={`${classes.tabsContainer}`}>
      <TabList
        className='d-sm-none d-flex justify-content-center flex-gap-2 container'
        {...tabState}
      >
        {TABS.map((id, tabPanelIndex) => (
          <Tab
            key={id}
            {...tabState}
            id={id}
            onClick={() => handleTabClick(tabPanelIndex)}
          >
            <span
              className={cn(
                'mc-corners--8 d-flex align-items-center justify-content-center flex-gap-1 mc-py-2 mc-px-5',
                tabState.selectedId === id ? classes.tabActive : classes.tab,
              )}
            >
              {t(`landing.goals.tabs.${id}.title`)}
            </span>
          </Tab>
        ))}
      </TabList>
      <div
        className={`${classes.tabPanels} d-flex flex-gap-4`}
        ref={tabPanelContainerRef}
        onScroll={handleScroll}
      >
        {TABS.map((id, index) => (
          <TrackingContainer
            key={id}
            className={classes.tabPanel}
            onVisible={() => handleTabVisible(index)}
            config={{ threshold: 1, duration: { visible: 200 } }}
          >
            <h3 className='mc-text--center d-none d-sm-block'>
              {t(`landing.goals.tabs.${id}.title`)}
            </h3>
            <ul
              className='mc-mt-6 mc-text--center d-flex flex-column flex-gap-2'
              ref={tabPanelRefs[index]}
            >
              {(
                t(`landing.goals.tabs.${id}.items`, {
                  returnObjects: true,
                }) as string[] | undefined
              )?.map((item, itemIndex) => (
                <li
                  key={itemIndex}
                  className={cn(
                    'mc-px-2 mc-py-4 mc-corners--12 mc-text-large flex-shrink-0',
                    classes.listItem,
                    { [classes.listItemActive]: tabState.selectedId === id },
                  )}
                >
                  {item}
                </li>
              ))}
            </ul>
          </TrackingContainer>
        ))}
      </div>
      <div className={classes.tabsBottomGradient} />
    </div>
  )
}
