import React from 'react'
import type { TabStateReturn } from 'reakit/Tab'
import { useTabState } from 'reakit/Tab'

type TabsContextValue = {
  state: TabStateReturn
}

export const TabsContext = React.createContext<TabsContextValue>({
  state: {} as TabStateReturn,
})

const Tabs = ({
  children,
  manual,
  selectedId,
}: {
  children: React.ReactNode
  manual?: boolean
  selectedId?: string
}) => {
  const state = useTabState({ manual, selectedId })

  return (
    <TabsContext.Provider value={{ state }}>{children}</TabsContext.Provider>
  )
}

export default Tabs
