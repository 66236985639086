import React, { forwardRef } from 'react'
import {
  TabList as BaseTabList,
  TabListProps as BaseTabListProps,
} from 'reakit/Tab'

type TabListProps = {
  className?: string
} & BaseTabListProps

const TabList = forwardRef<HTMLDivElement, TabListProps>(
  ({ className = '', ...props }, ref) => (
    <BaseTabList ref={ref} className={className} {...props} />
  ),
)

TabList.displayName = 'TabList'

export default TabList
