import React, { useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'

import { useTranslation } from '@mc/i18n'
import { Button } from '@mc/design-system'
import { TrackingContainer } from '@mc/visibility'
import { InstructorCardRowItem } from '@mc/persona-api'

import {
  TrackingSectionContainer,
  ComingSoonMentors,
} from '../../../components'
import { ROW_TRACKING } from '../utils'
import { usePersonaEvents } from '../../../events'
import { Locations } from '../../../types/tracking'
import { useNonCommerceGoldStandardEvents } from '../../../hooks/tracking/useNonCommerceGoldStandardEvents'
import { useCtaToPurchaseUrl } from '../../../utils/cta'

import { MentorCard } from './MentorCard'

interface MentorsSectionProps {
  mentors?: InstructorCardRowItem[]
  comingSoonMentors?: InstructorCardRowItem[]
  rowAnalytics?: {
    rowName: string
    rowPositionIndex: number
  }
  comingSoonRowAnalytics?: {
    rowName: string
    rowPositionIndex: number
  }
  ctaUrl: string
}

export const MentorsSection = ({
  mentors,
  comingSoonMentors,
  rowAnalytics = ROW_TRACKING.yourAiMentors,
  comingSoonRowAnalytics = ROW_TRACKING.comingSoon,
  ctaUrl,
}: MentorsSectionProps) => {
  const { t } = useTranslation('@mc/persona')
  const { slug: instructorSlug } = useRouter().query
  const [currentAudio, setCurrentAudio] = useState<string | null>(null)
  const setPlayingAudio = (audio: string | null) => setCurrentAudio(audio)
  const getCtaToPurchaseUrl = useCtaToPurchaseUrl()

  const {
    trackPersonaRowTileViewed,
    trackPersonaRowTileClicked,
    trackPersonaSignClick,
  } = usePersonaEvents()
  const { trackPurchaseClick } = useNonCommerceGoldStandardEvents('discovery')

  const handleVisible =
    (mentor: InstructorCardRowItem, position: number) => () =>
      trackPersonaRowTileViewed({
        ...rowAnalytics,
        tilePositionIndex: position.toString(),
        tileTitle: mentor.text?.title,
        tileSubtitle: mentor.text?.tags?.join('  •  '),
        tileLink: mentor.redirectUrls?.card,
        tileInstructorSlug: mentor.analytics?.instructorSlug,
      })

  const handleCTAClick = () => {
    const pageSource = 'persona landing page'
    const trackProps = {
      cta: 'sign up',
      location: rowAnalytics.rowName as Locations,
    }
    trackPersonaSignClick({
      ...trackProps,
      additionalAnalytics: { pageSource },
    })
    trackPurchaseClick({ ...trackProps, pageSource })
  }

  return (
    <>
      <TrackingSectionContainer
        analytics={rowAnalytics}
        config={{ threshold: 0.3 }}
      >
        <div className='position-relative mc-py-10'>
          <h2 className='mc-text-h1 mc-text--center mc-mb-9 mc-mx-auto'>
            {instructorSlug
              ? t('bio.mentors.title')
              : t('landing.mentors.title')}
          </h2>
          <div className='d-flex flex-gap-8 flex-wrap align-items-center justify-content-center'>
            {mentors?.map((mentor, i) => (
              <TrackingContainer
                config={{ threshold: 0.3 }}
                key={`${mentor.text?.title}-${i}`}
                onVisible={handleVisible(mentor, i)}
              >
                <MentorCard
                  mentor={mentor}
                  onClick={() => {
                    trackPersonaRowTileClicked({
                      ...rowAnalytics,
                      tilePositionIndex: i.toString(),
                      tileTitle: mentor?.text?.title,
                      tileSubtitle: mentor?.text?.tags?.join('  •  '),
                      tileLink: mentor?.redirectUrls?.card,
                      tileInstructorSlug: mentor?.analytics?.instructorSlug,
                    })
                  }}
                  currentAudio={currentAudio}
                  onPlay={setPlayingAudio}
                  analytics={rowAnalytics}
                />
              </TrackingContainer>
            ))}
          </div>
          <div className='mc-text--center'>
            <Button
              className='mc-mt-9 mc-px-11 mc-py-4 mc-corners--12'
              as={Link}
              href={getCtaToPurchaseUrl(ctaUrl, 'discovery')}
              onClick={handleCTAClick}
            >
              {t('landing.plans.cta')}
            </Button>
          </div>
        </div>
      </TrackingSectionContainer>
      <div>
        {!!comingSoonMentors?.length && (
          <TrackingSectionContainer
            analytics={comingSoonRowAnalytics}
            config={{ threshold: 0.1 }}
          >
            <div className='position-relative mc-my-10'>
              <h2 className='mc-text-h1 mc-text--center mc-mb-9 mc-mx-auto'>
                {t('landing.comingSoon.title')}
              </h2>
              <ComingSoonMentors
                mentorList={comingSoonMentors}
                comingSoonRowAnalytics={comingSoonRowAnalytics}
              />
            </div>
          </TrackingSectionContainer>
        )}
      </div>
    </>
  )
}
