import React from 'react'

import { useTranslation } from '@mc/i18n'

import { GoalTabs } from './GoalTabs'

import { TrackingSectionContainer } from '../../../components'
import { ROW_TRACKING, RowAnalytics } from '../utils'

export const GoalsSection = ({
  rowAnalytics = ROW_TRACKING.aiPoweredByPros,
}: {
  rowAnalytics?: RowAnalytics
}) => {
  const { t } = useTranslation('@mc/persona')

  return (
    <TrackingSectionContainer analytics={rowAnalytics}>
      <div className='container d-flex flex-column align-items-center mc-corners--lg mc-px-8 mc-py-10'>
        <h2 className={`mc-text-h1 mc-text--center mc-mb-8`}>
          {t('landing.goals.title')}
        </h2>
        <GoalTabs rowAnalytics={rowAnalytics} />
      </div>
    </TrackingSectionContainer>
  )
}
