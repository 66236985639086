import React from 'react'

import {
  Badge,
  Button,
  IconCheckDefault,
  IconNotificationDefault,
} from '@mc/design-system'
import { useTranslation } from '@mc/i18n'
import { InstructorCardRowItem } from '@mc/persona-api'

import AIBadgeFilled from '../../assets/ai-badge-filled-white.svg'
import { Image } from '..'

import styles from './ComingSoonMentorCard.module.scss'

export const ComingSoonMentorCard = ({
  mentor,
  onClickNotify,
  isNotified,
  width = 495,
  height = 495,
}: {
  mentor: InstructorCardRowItem
  onClickNotify: (mentor: { slug: string; name: string }) => void
  isNotified: boolean
  width?: number
  height?: number
}) => {
  const { t } = useTranslation('@mc/persona')

  return (
    <>
      <Image
        src={mentor?.images?.instructor1x1?.url || ''}
        alt={mentor?.images?.instructor1x1?.altText || ''}
        width={width}
        height={height}
        className='mc-corners--lg mc-opacity--muted'
        style={{ maxWidth: '100%', height: 'auto' }}
      />
      <AIBadgeFilled
        className={styles.badge}
        alt={t('common.aiAlt')}
        height={23}
      />
      {mentor.topRightBadge && (
        <Badge
          className={styles.topRightBadge}
          style={{ backgroundColor: mentor.topRightBadge.backgroundColor }}
        >
          <p className='mc-theme-dark'>{mentor.topRightBadge.text?.primary}</p>
          {mentor.topRightBadge.images?.iconTrailing && (
            <div className='d-flex mc-ml-2'>
              <Image
                src={mentor.topRightBadge.images?.iconTrailing.url}
                alt={mentor.topRightBadge.images?.iconTrailing.altText || ''}
                width={mentor.topRightBadge.images?.iconTrailing.width}
                height={mentor.topRightBadge.images?.iconTrailing.height}
              />
            </div>
          )}
        </Badge>
      )}
      <div
        className={`${styles.textContainer} mc-text--center mc-p-6 mc-pb-8 mc-corners--lg d-flex flex-column align-items-center`}
      >
        <h3 className='mc-text-h2 mc-text--bold mc-mb-3'>
          {mentor?.text?.title}
        </h3>
        <p className='mc-text-h6 mc-text-color--medium'>
          {mentor?.text?.tags?.join('  •  ')}
        </p>
        <Button
          kind='secondary'
          onClick={() => {
            if (isNotified) {
              return
            }
            onClickNotify({
              slug: mentor?.analytics?.instructorSlug || '',
              name: mentor?.text?.title || '',
            })
          }}
          disabled={isNotified}
          fullWidth
          className={`${styles.suggestion} ${styles.notifyMeButton} mc-mt-4 d-flex align-items-center justify-content-center mc-corners--2`}
        >
          {isNotified ? (
            <IconCheckDefault className='mc-mr-2' height={2} width={2} />
          ) : (
            <IconNotificationDefault className='mc-mr-2' />
          )}
          {isNotified ? t('mentorNotify.ctaSet') : t('mentorNotify.cta')}
        </Button>
      </div>
    </>
  )
}
