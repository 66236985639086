import React from 'react'
import omit from 'lodash/omit'
import { FieldRenderProps } from 'react-final-form'
import PropTypes from 'prop-types'

import Input from '../Input'
import FormGroup from '../FormGroup'
import { parseMessage } from '../Forms/utils'
import { PROP_TYPE_REDUX_FORM_ELEMENT } from '../Forms/constants'

type InputFieldProps = FieldRenderProps<string, HTMLElement>

const InputField = ({
  className,
  help,
  input,
  label,
  maxlength,
  meta,
  optional,
  required,
  // pen-4698_v1
  inputClassName = '',
  ...props
}: InputFieldProps) => {
  const error = parseMessage(meta.error || props.error)?.toString()
  const success = parseMessage(props.success)?.toString()
  const touched = meta.touched || props.touched

  return (
    <FormGroup
      className={className}
      disabled={input.disabled}
      error={error}
      help={help}
      label={label}
      maxlength={maxlength}
      name={input.name}
      optional={optional}
      required={required}
      success={success}
      touched={touched}
      value={input.value}
    >
      <Input
        aria-describedby={`${input.name}-help-text ${input.name}-info-text`}
        error={error}
        className={inputClassName}
        required={required}
        success={success ?? false}
        touched={touched}
        maxLength={maxlength}
        {...omit(input, ['success', 'touched'])}
        {...omit(props, ['success', 'touched'])}
      />
    </FormGroup>
  )
}

InputField.propTypes = {
  ...PROP_TYPE_REDUX_FORM_ELEMENT,
} as PropTypes.ValidationMap<InputFieldProps>

export default InputField
