import React, { forwardRef, useContext } from 'react'
import { Tab as BaseTab } from 'reakit/Tab'

import { TabsContext } from './Tabs'

interface TabProps {
  id?: string
  className?: string
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>
}

const Tab = forwardRef<HTMLButtonElement, TabProps>(
  ({ id, children, className = '', ...props }, ref) => {
    const { state } = useContext(TabsContext)
    return (
      <BaseTab id={id} ref={ref} className={className} {...state} {...props}>
        {children}
      </BaseTab>
    )
  },
)

Tab.displayName = 'Tab'

export default Tab
